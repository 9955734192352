<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pt-5">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="100"
          max-width="200"
          alt="logo"
          contain
          eager
          class="app-logo"
        ></v-img>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pt-4">
      <div v-for="item in menuItems" :key="item.text">
        <nav-menu-link v-if="$tools.authorize(item.auth)"
          :to="item.route"
          :title="item.text"
          :icon="item.icon">
        </nav-menu-link>
      </div>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
import {
  mdiChat,
  mdiHomeOutline,
  mdiFormatListCheckbox,
  mdiCheckDecagramOutline,
  mdiChevronTripleDown,
  mdiFileMoveOutline,
  mdiEmailSyncOutline,
  mdiCheckboxMarkedOutline,
  mdiDeleteVariant,
  mdiAccountCircleOutline,
  mdiAccountMultiple,
  mdiAccountCogOutline,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import { Role } from "@/utils/constant";

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data(){
    return {
      menuItems: [
        { icon: this.icons.mdiHomeOutline, text: "Overview", route: "overview", auth: [] },
        { icon: this.icons.mdiChat, text: "Message", route: "message", auth: [] },
        { icon: this.icons.mdiFormatListCheckbox, text: "Review", route: "review", auth: [Role.REVIEW] },
        { icon: this.icons.mdiCheckDecagramOutline, text: "Accepted", route: "accepted", auth: [Role.ACCEPTED] },
        { icon: this.icons.mdiChevronTripleDown, text: "Processing", route: "processing", auth: [Role.PROCESSING] },
        { icon: this.icons.mdiFileMoveOutline, text: "Delivered", route: "delivered", auth: [Role.DELIVERED] },
        { icon: this.icons.mdiEmailSyncOutline, text: "Revision", route: "revision", auth: [Role.REVISION] },
        { icon: this.icons.mdiCheckboxMarkedOutline, text: "Completed", route: "completed", auth: [Role.COMPLETED] },
        { icon: this.icons.mdiDeleteVariant, text: "Trash", route: "trash", auth: [Role.TRASH] },
        { icon: this.icons.mdiAccountCircleOutline, text: "Profile", route: "Profile", auth: [] },
        { icon: this.icons.mdiAccountMultiple, text: "Clients", route: "clients", auth: [Role.CLIENTS] },
        { icon: this.icons.mdiAccountCogOutline, text: "Members", route: "members", auth: [Role.SUPER] },
      ],
    }
  },
  setup() {
    return {
      icons: {
        mdiChat,
        mdiHomeOutline,
        mdiFormatListCheckbox,
        mdiCheckDecagramOutline,
        mdiChevronTripleDown,
        mdiFileMoveOutline,
        mdiEmailSyncOutline,
        mdiCheckboxMarkedOutline,
        mdiDeleteVariant,
        mdiAccountCircleOutline,
        mdiAccountMultiple,
        mdiAccountCogOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

</style>
